import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import { Box } from 'rebass';

export const PaginationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ul: {
    maxWidth: 770,
    display: 'flex',
    flexWrap: 'wrap',
  },

  li: {
    display: 'block',
    margin: 5,

    [media('md')]: {
      margin: 7.5,
    },
  },

  'li.disabled': {
    opacity: 0.4,
  },

  'li.selected': {
    a: {
      borderColor: theme.colors.secondaryBright,
      color: theme.colors.secondaryBright,
    },
  },

  a: {
    fontSize: 9,
    fontWeight: 700,
    textDecoration: 'none',
    width: 35,
    height: 35,
    color: 'black',
    border: '1px solid black',
    borderRadius: '5px',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',

    [media('md')]: {
      fontSize: 13,
      width: 52,
      height: 52,
    },
  },
}));

PaginationWrapper.defaultProps = {
  px: { _: 1, lg: 3 },
  py: { _: 2, lg: 4 },
};
