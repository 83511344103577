import React from 'react';
import { graphql } from 'gatsby';
import { Query, Craft_Blog, Craft_BlogArticlesArticle } from '~/query';
import PageHeaderSpacing from '~/components/common/PageHeaderSpacing';
import PageHeader from '~/components/common/PageHeader';
import Seo from '~/components/common/Seo';
import BlogArticleTeaser from '~/components/common/BlogArticleTeaser';
import BlogPagination from '~/components/common/BlogPagination';

type TemplateBlogProps = {
  data: Query;
  pageContext: any;
};

/**
 * Template: Blog
 *
 * Use this template in correspondence with a craft
 * section with the following properties:
 *
 * Type: single
 * Name: blog
 * URI: {parent.uri}/{slug}
 *
 * And an entry type with the following properties:
 * Name: blog
 */
export default function TemplateBlog({ data, pageContext }: TemplateBlogProps) {
  const page = (data?.craft?.entry ?? {}) as Craft_Blog;
  const articles = (data?.craft?.entries ?? []) as Craft_BlogArticlesArticle[];
  const { title, seoTitle, seoDescription, seoCanonical, fullUri } = page;

  const pageHeaderEnabled = Boolean(page.pageHeaderEnabled) ?? undefined;
  const pageHeaderImage =
    (page.pageHeaderImage && page.pageHeaderImage[0]) ?? undefined;
  const blogHeaderText = page.blogHeaderText ?? undefined;

  const blogOverviewPageCount = (pageContext?.blogOverviewPageCount ??
    '') as Number;
  const blogCurrentPage = (pageContext?.blogCurrentPage ?? '') as Number;
  const paginationBasePath = (pageContext?.paginationBasePath ?? '') as String;
  const blogHome = (pageContext?.blogHome ?? '') as String;

  return (
    <>
      <Seo
        title={seoTitle ?? title}
        description={seoDescription ?? undefined}
        canonical={seoCanonical ?? undefined}
      />
      {pageHeaderEnabled ? (
        <PageHeader
          overlay
          backgroundImage={pageHeaderImage ?? undefined}
          title={title}
          description={blogHeaderText}
          bigSpaceBottom={true}
        />
      ) : (
        <PageHeaderSpacing />
      )}
      {articles.map((article, index) => {
        return (
          <BlogArticleTeaser
            article={article}
            key={article.id}
            variant={index == 0 && 'hero'}
            blogUri={fullUri}
          />
        );
      })}
      <BlogPagination
        totalPages={blogOverviewPageCount}
        currentPage={blogCurrentPage}
        basePath={paginationBasePath}
        home={blogHome}
      />
    </>
  );
}

export const query = graphql`
  query QueryBlog($id: [Int]!, $site: String!, $subItemOffset: Int!) {
    craft {
      entry(id: $id, site: $site) {
        id
        title
        fullUri
        ... on Craft_Blog {
          blogHeaderText
          pageHeaderEnabled
          pageHeaderImage {
            ...CraftImageFluid
          }
          # SEO
          seoTitle
          seoDescription
        }
      }
      entries(
        site: $site
        type: BlogArticlesArticle
        limit: 6
        offset: $subItemOffset
        orderBy: "postDate desc"
      ) {
        ... on Craft_BlogArticlesArticle {
          id
          title
          image: blogArticleTeaserImage {
            ...CraftImageFluid
          }
          link: fullUri
          postDate
          shortText: blogArticleLongTeaserText
          longText: blogArticleShortTeaserText
          blogCategories {
            title
            slug
          }
        }
      }
    }
  }
`;
