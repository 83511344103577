import React from 'react';
import { navigate } from 'gatsby';
import ReactPaginate from 'react-paginate';
import {
  PaginationWrapper
} from './BlogPagination.styles';


export default function BlogPagination(props: any) {
  const { currentPage, totalPages, basePath, home } = props;

  return (
    <PaginationWrapper>
      <ReactPaginate
        pageCount={totalPages}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        initialPage={currentPage - 1}
        disableInitialCallback
        previousLabel="&larr;"
        nextLabel="&rarr;"
        onPageChange={(e)=>{
          const page = e.selected;
          navigate(page == 0 ? home : basePath + (page + 1));
        }}
        hrefBuilder={(page) => page == 1 ? home : basePath + page}
      />
    </PaginationWrapper>
  );
}

BlogPagination.defaultProps = {};
