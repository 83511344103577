import React from 'react';
import { Craft_DateFormatTypes } from '~/query';

import { DateText } from './BlogDate.styles'

const dateFormatter = new Intl.DateTimeFormat('de-DE', { year: '2-digit', month: '2-digit', day: '2-digit' });

export default function BlogDate({date}: any) {
  
  const datePreformatted = dateFormatter ? new Date(date*1000) : null;
  const postDateFormatted = datePreformatted ? dateFormatter.format(datePreformatted) : null;

  return (
    <DateText>{postDateFormatted}</DateText>
  );
}

BlogDate.defaultProps = {
  date: null
};

type BlogDate = {
  date: Craft_DateFormatTypes;
};

