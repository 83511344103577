import styled from '~/utils/styled';
import { Box, Flex, Card } from 'rebass';
import media from '~/utils/mediaqueries';

import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';
import BaseHeadline from '~/components/common/Headline';
import BaseLink from '~/components/common/Link';
import BaseButton from '~/components/common/Button';
import Text from '~/components/common/Text';

export const BlogArticleTeaserStyled = styled(Box)(
  ({ theme }) => ({
    maxWidth: 1300,
    margin: '0 auto 0',
    marginBottom: 72,

    a: {
      textDecoration: 'none',
    },

    [media('md')]: {
      marginBottom: 100,
    },
  }),
  props =>
    props.variant === 'hero' && {
      marginTop: -80,
    },
);

export const Image = styled(CraftGatsbyImage)(({ theme }) => ({}));

export const ImageWrapperStyled = styled(Box)(
  {
    overflow: 'hidden',
    position: 'relative',
  },
  props =>
    props.variant === 'hero' && {
      [media('md')]: {
        paddingTop: `${(386 / 1198) * 100}%`,
      },
    },
);

export const ImageContainerStyled = styled(Box)(
  {},
  props =>
    props.variant === 'hero' && {
      [media('md')]: {
        width: '100%',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: 0,
      },
    },
);

export const Headline = styled(BaseHeadline)({
  color: 'inherit',
});

export const Link = styled(BaseLink)({
  display: 'block',

  '&:hover, &:focus, &:active': {
    WebkitTapHighlightColor: 'transparent',
  },
});

export const ContentWrapper = styled(Flex)({
  flexDirection: 'column',
  paddingRight: 0,
  paddingLeft: 0,

  [media('md')]: {
    paddingRight: 60,
  },
});

export const ContentMain = styled('div')({
  maxWidth: 700,
});

export const MetaInfoStyled = styled('div')(({ theme }) => ({
  fontSize: 11,
  color: theme.colors.dim,
  fontWeight: 600,
  display: 'flex',
}));

export const Button = styled(BaseButton)({
  marginTop: 20,
});

export const CategoryStyled = styled('span')({
  marginLeft: 30,
  display: 'inline-flex',
  alignItems: 'center',

  div: {
    marginRight: 3,
  },
});

export const TextStyled = styled(Text)({
  fontSize: 15,
});
