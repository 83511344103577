import React from 'react';
import Parser from '~/components/common/Parser';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import { Box, Flex, Card } from 'rebass';
import Icon from '~/components/common/Icon';
import Link from '~/components/common/Link';
import BlogDate from '~/components/common/BlogDate';

import {
  BlogArticleTeaserStyled,
  ContentWrapper,
  MetaInfoStyled,
  CategoryStyled,
  TextStyled,
  ContentMain,
  Headline,
  Button,
  Image,
  ImageWrapperStyled,
  ImageContainerStyled,
} from './BlogArticleTeaser.styles';

export default function BlogArticleTeaser({ article, variant, blogUri }: any) {
  const {
    image,
    title,
    shortText,
    longText,
    link,
    postDate,
    blogCategories,
  } = article;
  const t = useCraftTranslations();
  const linkProps = {
    to: link,
  };

  const category = (blogCategories?.[0]?.title ?? undefined) as String;
  const categoryLink = (`${blogUri}/category/${blogCategories?.[0]?.slug}` ??
    '') as string;

  return (
    <BlogArticleTeaserStyled variant={variant}>
      <Flex
        flexWrap="wrap-reverse"
        paddingX={30}
        flexDirection={variant == 'hero' ? 'column-reverse' : 'row'}
      >
        <Box width={variant == 'hero' ? 1 : [1, 1, 1 / 2]}>
          <ContentWrapper p={1}>
            <ContentMain>
              <MetaInfoStyled>
                {postDate && <BlogDate date={postDate} />}
                {category && (
                  <Link to={categoryLink}>
                    <CategoryStyled>
                      <Icon name={'Globe'} />
                      <span>{category}</span>
                    </CategoryStyled>
                  </Link>
                )}
              </MetaInfoStyled>
              {title && (
                <Headline level="h3">
                  <Parser html={title} />
                </Headline>
              )}
              {shortText && (
                <TextStyled mb={2} fontSize={1}>
                  {variant == 'hero' ? longText : shortText}
                </TextStyled>
              )}
            </ContentMain>
            <div>
              <Button to={link} variant="primary">
                {t('read more')}
              </Button>
            </div>
          </ContentWrapper>
        </Box>
        <Box width={variant == 'hero' ? 1 : [1, 1, 1 / 2]}>
          {image && (
            <ImageWrapperStyled variant={variant}>
              <ImageContainerStyled variant={variant}>
                <Image
                  fluid={image[0]}
                  args={{
                    maxWidth: variant == 'hero' ? 1400 : 600,
                    width: image.width,
                  }}
                />
              </ImageContainerStyled>
            </ImageWrapperStyled>
          )}
        </Box>
      </Flex>
    </BlogArticleTeaserStyled>
  );
}

BlogArticleTeaser.defaultProps = {};
